import * as React from "react";
import {Link} from "gatsby";

const bannerStyle={
    "background":"linear-gradient(to left, #2e88a2 0%, #88ddf5 50.39%, #3763eb 100%)",
    "minHeight":125,
    "maxHeight":'15%',
};

const handlePrepageName = (prepageName) =>{
  if(prepageName){
    return <li className="breadcrumb-item active " aria-current="page">
            <Link to="/">{prepageName}</Link>
          </li>
  }
};
// style="background-image: url('assets/img/bg/common-bg.svg')"
const Banner = (props) => {
  return (
    <section className="page-banner-section pt-75 pb-15 img-bg" style={bannerStyle}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="banner-content">
              <h4 className="text-white">{props.pageName}</h4>
              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/">Home</Link>
                    </li>
                    {handlePrepageName(props.prepageName)}
                    <li className="breadcrumb-item active" aria-current="page">
                      {props.postpageName}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
