import * as React from "react";
// import { graphql, Link } from "gatsby"
import { Link } from "gatsby";


const downArea = {
  position: "fixed",
  backgroundColor: "#FFF",
  bottom: 0,
  left: 0,
  height:"25px",
  width: "100%",
  zIndex: 99,
  transition: "all .3s ease-out 0s",
  padding: 0,
};

const lineStyle = {
  background: "linear-gradient(to left, #2e88a2 0%, #88ddf5 50.39%, #3763eb 100%)",
  height:"25px",
  color:"#FFF",
  textAlign:"center",
}

const linkStyle = {
  color:"#FFF",
}

const DOWNBar = () => {
  return (
    <div style={downArea}>
      <div style={lineStyle}><Link to="/" style={linkStyle} >Servizio di Alta Qualità</Link></div>
    
    </div>
  );
};

export default DOWNBar;
