import { fadeInUpStyle, fadeInDownStyle } from "../styles/style";
import * as React from "react";
import Aziende from "../images/works/Aziende.jpg";
import Capannoni from "../images/works/Capannoni.jpg";
import Civili from "../images/works/Civili.jpg";
import Condominiali from "../images/works/Condominiali.jpg";
import Industriali from "../images/works/Industriali.jpg";
import Laboratorio from "../images/works/Laboratorio.jpg";
import LuciPavimenti from "../images/works/LuciPavimenti.jpg";
import Magazzini from "../images/works/Magazzini.jpg";
import Moquette from "../images/works/Moquette.jpg";
import Officine from "../images/works/Officine.jpg";
import Pavimenti from "../images/works/Pavimenti.jpg";
import Pubblici from "../images/works/Pubblici.jpg";
import Ricettive from "../images/works/Ricettive.jpg";
import Riposo from "../images/works/Riposo.jpg";
import Ristoranti from "../images/works/Ristoranti.jpg";
import Sanitarie from "../images/works/Sanitarie.jpg";
import Sportive from "../images/works/Sportive.jpg";
import Tecnici from "../images/works/Tecnici.jpg";
import Uffici from "../images/works/Uffici.jpg";
import Vetri from "../images/works/Vetri.jpg";

const works = [
  {
    title: "Pulizie Civili",
    img:Civili,
  },
  {
    title: "Pulizie Industriali",
    img:Industriali,
  },
  {
    title: "Pulizie Aziende",
    img:Aziende,
  },
  {
    title: "Pulizie Uffici",
    img:Uffici,
  },
  {
    title: "Lavaggio Vetri",
    img:Vetri,
  },
  {
    title: "Lavaggio Moquette",
    img:Moquette,
  },
  {
    title: "Lavaggio Pavimenti",
    img:Pavimenti,
  },
  {
    title: "Lucidatura Pavimenti",
    img:LuciPavimenti,
  },
  {
    title: "Pulizie per hotel e strutture ricettive",
    img:Ricettive,
  },
  {
    title: "Pulizie per negozi e locali pubblici",
    img:Pubblici,
  },
  {
    title: "Pulizie ristoranti",
    img:Ristoranti,
  },
  {
    title: "Pulizie uffici tecnici",
    img:Tecnici,
  },
  {
    title: "Pulizia capannoni",
    img:Capannoni,
  },
  {
    title: "Pulizie magazzini",
    img:Magazzini,
  },
  {
    title: "Pulizie laboratorio",
    img:Laboratorio,
  },
  {
    title: "Pulizie per strutture sanitarie",
    img:Sanitarie,
  },
  {
    title: "Pulizia officine",
    img:Officine,
  },
  {
    title: "Pulizie Condominiali",
    img:Condominiali,
  },
  {
    title: "Pulizie per case di cura e di riposo",
    img:Riposo,
  },
  {
    title: "Pulizie Centri Sportive Palestre",
    img:Sportive,
  },
];

const Works = (props) => {
  return (
    <section id="blog" className="blog-section pt-90">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-xl-6 col-lg-6">
            <div className="section-title mb-60">
              <span
                className="wow fadeInDown"
                data-wow-delay=".2s"
                style={fadeInDownStyle}
              ></span>
              <h2
                className="wow fadeInUp"
                data-wow-delay=".4s"
                style={fadeInUpStyle}
              >
                Aree di intervento per le pulizie dei condomini a Milano
              </h2>
            </div>
          </div>
          <div className="col-xl-12 col-lg-1s2">
            <div className="section-title mb-60">
              <p
                className="wow fadeInUp"
                data-wow-delay=".4s"
                style={fadeInUpStyle}
              >
                La pulizia degli uffici è un lavoro fondamentale per un’impresa,
                è indispensabile per mantenere l’ordine e dare ai clienti
                un’immagine di serietà e responsabilità dell’impresa in
                generale. Per tale motivo il servizio di pulizie uffici di SH
                Logistica e Servizi è molto richiesto e noi offriamo solo
                personale altamente qualificato, le cui caratteristiche
                principali sono la responsabilità e l’efficienza. Oltre
                all’ordinaria pulizia e sanificazione degli ambienti, il nostro
                staff esegue a scadenze prefissate quei servizi atti a garantire
                una perfetta manutenzione degli stabili come: la pulizia vetri,
                la lucidatura e ceratura pavimenti; la pulizia dei
                controsoffitti e apparecchi illuminanti; la pulizia degli
                arredi; la sanificazione e pulizia di apparati tecnici di uso
                comune quali computer e telefonia; la manutenzione di filtri e
                condotte impianti aria. Per informazioni sui nostri servizi di
                pulizie uffici a Milano e dintorni non esitare a contattarci ai
                recapiti presenti sul sito. Un nostro operatore risponderà a
                tutte le tue domande. La nostra sede si trova a Bresso, Milano,
                e siamo operativi principalmente nel nord Italia dove lavoriamo
                con clienti e aziende della Lombardia e in particolare della
                zona di: Milano, Como, Bergamo, Monza e province, e nel Veneto,
                Treviso, Verona, Vicenza e province.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {works.map(work => (
          <div className="col-xl-4 col-lg-4 col-md-6" key={work.title}>
            <div
              className="single-blog mb-40 wow fadeInUp"
              data-wow-delay=".2s"
              style={fadeInUpStyle}
            >
              <div className="blog-img">
                <a href="blog-single.html">
                  <img src={work.img} alt={work.title} />
                </a>
                <span className="date-meta"></span>
              </div>
              <div className="blog-content">
                <h4>
                  {work.title}
                </h4>
              </div>
            </div>
          </div>
          ))};
        </div>
      </div>
    </section>
  );
};

export default Works;
