import img from "../../images/other_services/lavapavimenti_.jpg";
import Service from "../../components/Service";
import {list_style,pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Lavaggio Vetri / Vetrine " prepageName="Servizio" postpageName="Lavaggio Vetri / Vetrine" />
      <Service
        title="Lavaggio Vetrate,Vetri Insegne, Tapparelle, Veneziane"
        text="Non hai tempo per pulire? 
        Pulire le vetrate, le insegne, tapparelle e veneziane è lavoro noioso e faticoso che occupa molto tempo.
         Sia le aziende, sia i privati ne hanno bisogno. 
        Con il lavaggio e la pulizie delle tapparelle e Veneziane eviti l’usura. Con Manutenpul puoi toglierti il peso della pulizia. 
        I professionisti Manutenpul hanno"
        img={img}
      >
        <lu style={list_style}>
          <li><span className="material-icons">military_tech</span>Anni di esperienza</li>
          <li><span className="material-icons">sentiment_very_satisfied</span>Referenziati</li>
          <li><span className="material-icons">task_alt</span>Certificati da noi</li>
        </lu>
        <br />
        <br />
        Con Manutenpul avrai un servizio personalizzato.
        I nostri servizi consistono in: 
        <lu>
            <li>lavaggio e pulizia di vetrate, di specchi e vetri interne</li>
            <li>lavaggio e pulizia di vetrate, di specchi e vetri esterne</li>
            <li>Lavaggio e pulizia di insegne, Tapparelle, Veneziane</li>
            <li>lavaggio e pulizia di persiane e serrande, interne ed esterne, previo smontaggio: il lavaggio, infatti, non viene effettuato in opera, al fine di ottenere una pulizia più approfondita;</li>
            <li>lavaggio delle tapparelle, compresa la pulizia della superficie esterna </li>
            <li>le veneziane e le persiane  vengono pulite con un trattamento specifico, con prodotti di qualità scelti per ogni tipo di materiale (plastica, alluminio o legno).</li>
            <li>Manutenpul per ogni vostra necessità sarà a vostra disposizione.</li>
        </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
