import {fadeInUpStyle, fadeInDownStyle} from "../styles/style";
import banner from "../images/banner.webp";
import * as React from "react";
import { Link } from "gatsby";
import LeadForm from "./LeadForm";
const imgStyle={
  width:"100%",
  marginTop:"30px"
};

const Stylecorsive={
  fontStyle: "italic",
};



function renderImages(images=null){
  if(images){
    return  <div className="row">
              {images.map(image => (
              <div className="col-xl-4 col-lg-4 col-md-6" key={image.title}>
                <div
                  className="single-blog mb-40 wow fadeInUp"
                  data-wow-delay=".2s"
                  style={fadeInUpStyle}
                >
                  <div className="blog-img">
                    <a href="blog-single.html">
                      <img src={image.img} alt={image.title} />
                    </a>
                    <span className="date-meta"></span>
                  </div>
                  <div className="blog-content">
                    <h4 style={{textAlign:"center"}}>
                      {image.title}
                    </h4>
                  </div>
                </div>
              </div>
              ))}
            </div>;
  }
  return null;
}

const Service = (props) => {
  return (
    <section id="service" className="service-section pt-75 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 order-2 col-lg-6">
            <div className="section-title mb-60">
              <span
                className="wow fadeInDown"
                datawowdelay=".2s"
                style={fadeInDownStyle}
              ></span>
              <h2
                className="wow fadeInUp"
                datawowdelay=".4s"
                style={fadeInUpStyle}
              >
              {props.title}
              </h2>
              {props.text}
              <br/>
              {props.children}
            </div>
          </div>
          <div className="col-xl-6 order-1 col-lg-6">
            <div className="section-title mb-30">
              <img src={props.img} style={imgStyle}  alt="img" />
              <p style={Stylecorsive}>Il nostro staff è composto da collaboratori altamente qualificati, 
                sempre al passo con i tempi, con le normative vigenti e con l’etica in materia ambientale.</p>
            </div>
          </div>
        </div>
        { renderImages(props.images) }
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center mb-55">
              <p>Chiama ora e prenota il servizio adatto a te!&nbsp; <strong><a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
              <br/>
              <Link to="/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
            </div>
          </div>
          <LeadForm />
          <div className="col-12">
            <img alt="Covid-19 Pulizia" style={imgStyle} src={banner}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;