import * as React from "react";
import axios from "axios";
import Swal from "sweetalert2";

const handleSubmit = event =>{
    event.preventDefault();
    let phone = document.getElementById('phone').value;
    let email = document.getElementById('email').value;
    if(
        phone===null || phone==='' ||  phone===' '
        || email===null || email==='' ||  email===' '
        ){
        Swal.fire(
            'Ups!',
            "compilare il campo del telefono o dell'e-mail",
            'error'
        );
        throw new Error('This is not an error. This is just to abort javascript');
    }
    let formData = new FormData(event.target);
    axios.post(
      'https://erp.manutenpul.it/leads/enpoint', 
      formData,
      {
        headers: {
          // "X-Requested-With": "XMLHttpRequest",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data;",
        },
        responseType: 'json',
      }).then((response)=>{
        Swal.fire(
          'Grazie!',
          'Ti risponderemo il prima possibile',
          'success'
        ).then((success)=>{
          window.location.reload();
        });
      }).catch((error)=>{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'compilando il form',
        });
      });
  };

const LeadForm = (props) => {
  return (
    <section id="leads" style={{"backgroundColor":"#d7d7d7"}}>
      <div className="container">
        <div className="row">
        <form onSubmit={handleSubmit} className="contact-form">
                <div className="section-title text-center mb-30 mt-20 ">
                      <span
                        className="wow fadeInDown"
                        data-wow-delay=".2s"
                      >
                        Compila e ti contattiamo a breve!
                      </span>
                    </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="azienda">Tipo:</label>
                      <select
                        name="type"
                        id="type"
                      >
                        <option value="privato">Privato</option>
                        <option value="azienda">Azienda</option>
                      </select>

                    </div>
                    <div className="col-md-6">
                      <label htmlFor="full_name">Nome e Cognome:</label>
                      <input
                        type="text"
                        name="full_name"
                        id="full_name"
                        placeholder="Nome Cognome"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="phone">Telf. / Cell.:</label>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder="Inserisci.."
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Indirizzo Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="desc">Descrivi in modo preciso la tua richiesta:</label>
                      <textarea
                        name="description"
                        id="description"
                        placeholder="Es. : La pulizia degli uffici"
                        rows="5"
                        maxLength="500"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="button text-center mb-20">
                        <button type="submit" className="theme-btn">
                          Invia
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
          <div className="col-xl-6 col-lg-6"></div>
        </div>
      </div>
    </section>
  );
};

export default LeadForm;